import React from "react";
import { useMediaPredicate } from "react-media-hook";

import { defaultValues, model, schema } from "components/Newsletter/model";
import NewsletterQuestions from "./Questions";
import RecaptchaMessage from "components/RecaptchaMessage";

import * as styles from "./styles.module.scss";

type NewsletterProps = {};

const Newsletter: React.FC<NewsletterProps> = () => {
  const isMobile = useMediaPredicate("(max-width: 767px)");
  return (
    <div className={styles.newsletter}>
      <NewsletterQuestions defaultValues={defaultValues} formType="Newsletter" schema={schema} />
      {!isMobile && <RecaptchaMessage />}
    </div>
  );
};

export default React.memo(Newsletter);
