import { QuestionType } from "components/Question/types";
import yup from "modules/validation";

export const schema = yup.object().shape({
  email: yup.string().required().email().max(255, "Email must be at most 255 characters."),
  firstName: yup.string().required().name().max(255, "First name must be at most 255 characters."),
});

export type Model = {
  email: string;
  firstName: string;
};

export const defaultValues: Model = {
  email: "",
  firstName: "",
};

const email: QuestionType<Model> = {
  name: "email",
};

const firstName: QuestionType<Model> = {
  name: "firstName",
};

export const model = { email, firstName };
