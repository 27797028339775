import React from "react";
import { DeepPartial, UnpackNestedValue } from "react-hook-form";
import { useMediaPredicate } from "react-media-hook";

import { ButtonStyle } from "components/Button/constants";
import { QuestionType } from "components/Question/types";
import { Model } from "./model";
import { useDataLayer } from "modules/gtm";
import { useSendFormData } from "modules/sendFormData";
import * as Button from "components/Button";
import Questions from "components/Questions";
import Question from "components/Question";
import RecaptchaMessage from "components/RecaptchaMessage";
import yup from "modules/validation";

type ComponentProps = {
  defaultValues: UnpackNestedValue<DeepPartial<Model>>;
  formType: string;
  model: Record<string, QuestionType<Model>>;
  schema?: yup.ObjectSchema;
};

function NewsletterQuestions() {
  const isMobile = useMediaPredicate("(max-width: 767px)");
// useSendFormData({ formType });
  return (<div></div>
    // <Questions defaultValues={defaultValues} id={formType} onSubmit={handleSubmit} schema={schema}>
    //   {({ control, isFormSubmitting }) => (
    //     <>
    //       <Question
    //         control={control}
    //         label="Email address for newsletter*"
    //         question={model.email}
    //         isSubmitting={isFormSubmitting || isSubmitting}
    //       />
    //       <Question
    //         control={control}
    //         label="First name*"
    //         question={model.firstName}
    //         isSubmitting={isFormSubmitting || isSubmitting}
    //       />
    //       <div>
    //         {isMobile && <RecaptchaMessage />}
    //         <Button.Submit
    //           disabled={isFormSubmitting || isSubmitting}
    //           label="Signup"
    //           onClick={() => {
    //             useDataLayer({ action: "Signup Button Click", category: "Email Signup Form", label: "Footer" });
    //           }}
    //           isSubmitting={isFormSubmitting || isSubmitting}
    //           style={ButtonStyle.BRAND_AQUA}
    //         />
    //       </div>
    //     </>
    //   )}
    // </Questions>
  );
}

export default React.memo(NewsletterQuestions) as typeof NewsletterQuestions;
